import React, { useState } from 'react';
import { Modal, Form, Button, App, Input } from 'antd';
import { consoleLog, langError } from 'tools';
import { useFetchGetMsComplaintAdd  } from "hooks";

export const ModalAdd = ({ open, onCancel, onUpdateList }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果

    const [fetchAdd] = useFetchGetMsComplaintAdd();

    const handleSave = () => {
        form
            .validateFields()
            .then(values => {
                values.url = values.url.split('\n')
                setLoadingSave(true);
                fetchAdd(values, {
                    success: (data) => {
                        setLoadingSave(false);
                        if(onUpdateList){
                            onUpdateList();
                        }
                        onCancel();
                        notification.success({ "message": "添加成功" });
                    },
                    error: (error) => {
                        setLoadingSave(false);
                        notification.error({ 'message': langError(error.code) });
                    },
                });
            })
            .catch(info => {
                consoleLog('Validate Failed:', info);
            });
    }

    return (
        <Modal
            destroyOnClose
            open={open}
            title={"添加投诉信息"}
            onCancel={onCancel}
            width={600}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Form.Item
					label="Url"
					name="url"
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请填写Url",
						},
					]}
				>
					<Input.TextArea rows={5} />
				</Form.Item>
            </Form>
        </Modal>
    );
};
