import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, theme } from "antd";
import {
	LeftOutlined,
	RightOutlined,
	TeamOutlined,
	DollarOutlined,
	DashboardOutlined,
	TransactionOutlined,
	FileAddOutlined,
	CommentOutlined,
	UnorderedListOutlined,
	OrderedListOutlined,
	EyeOutlined,
	SendOutlined,
	KeyOutlined,
	FileSearchOutlined,
	BugOutlined, BarcodeOutlined, MailOutlined, BarsOutlined, UserOutlined, FieldTimeOutlined, WechatOutlined,
	MessageOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";

const { Sider } = Layout;

const labelItem = [
	{
		label:"Dashboard",
		key:"ms/dashboard",
		icon:<DashboardOutlined />
	},
	{
		label:"User",
		key:"ms/user",
		icon:<UserOutlined />
	},
	{
		label:"Invoice",
		key:"ms/invoice",
		icon:<DollarOutlined />
	},
	{
		label:"Subscription",
		key:"ms/subscription",
		icon:<TransactionOutlined />
	},
	{
		label: "Partner",
		key: "ms/partner",
		icon: <TeamOutlined />
	},
	{
		label:"Promocode",
		key:"ms/promocode",
		icon:<BarcodeOutlined />
	},
	{
		label:"Sequence",
		key:"ms/sequence",
		icon:<SendOutlined />
	},
	{
		label:"Redeem Code",
		key:"ms/redeemcode",
		icon:<OrderedListOutlined />
	},
	{
		label:"Enrichment",
		key:"ms/enrichment",
		icon:<FileAddOutlined />
	},
	{
		label:"Visitor",
		key:"ms/visitor",
		icon:<EyeOutlined />
	},
	{
		label:"Verify",
		key:"ms/verify",
		icon:<UnorderedListOutlined />
	},
	{
		label:"Feedback",
		key:"ms/feedback",
		icon:<CommentOutlined />
	},
	{
		label:"Recent Search",
		key:"ms/recentsearch",
		icon:<BugOutlined />
	},
	{
		label:"Mail",
		key:"ms/mail",
		icon:<MailOutlined />
	},
	{
		label:"Log",
		key:"ms/log",
		icon:<BarsOutlined />
	},
	{
		label:"SEO反馈",
		key:"ms/complaint",
		icon:<MessageOutlined />
	},
	{
		label:"Timeline",
		key:"ms/timeline",
		icon: <FieldTimeOutlined />
	},
	{
		label:"Chat",
		key:"ms/chat",
		icon: <WechatOutlined />
	},
	{
		label:"System Log",
		key:"ms/systemlog",
		icon:<BugOutlined />
	},
	{
		label:"Api Key",
		key:"ms/apikey",
		icon:<KeyOutlined />
	},
	{
		label:"Api Log",
		key:"ms/apilog",
		icon:<FileSearchOutlined />
	}
];

function getItem(label, key, icon, children, type) {
	return {
		key,
		icon,
		children,
		label,
		type
	};
}

export const LayoutPageSidebar = ({ selectedKey }) => {
	const [collapsed, setCollapsed] = useState(false);
	const user = useSelector((state) => state.user);
    const { permissions } = user;

	const navigate = useNavigate();
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	const getLabelItems = ()=>{
		let labelArr = [];
		labelItem.map(item =>{
			if(permissions.ms && permissions.ms.length>0){
				permissions.ms.map(ms =>{
					if(ms === item.key){
						return labelArr.push(getItem(item.label, item.key,item.icon));
					}
				})	
			}
		})
		return labelArr;
	}

	const items = [getItem('AroundDeal管理后台', 'ms', null, 
		getLabelItems(),
	'group')];

	return (
		<Sider
			trigger={
				<div style={{ background: "#ededed", color: "#000" }}>
					{collapsed ? <RightOutlined /> : <LeftOutlined />}
				</div>
			}
			collapsible
			collapsed={collapsed}
			onCollapse={(value) => {setCollapsed(value)}}
			style={{
				background: colorBgContainer,
				overflow: 'auto',
				height: 'calc(100vh - 98px)',
				position: 'fixed',
				left: 0,
				top: 50,
				bottom: 0,
			}}
		>
			<Menu
				mode="inline"
				defaultSelectedKeys={selectedKey}
				defaultOpenKeys={["sub1"]}
				onClick={({ key }) => {
					navigate(`/${key}`);
				}}
				style={{
					height: "100%",
					borderRight: 0,
				}}
				items={items}
			/>
		</Sider>
	);
};
