import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton, Dropdown, Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useFetchGetRiskifyRedeemcodeList, useFetchRiskifyRedeemcodeCancel } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalCreate } from "./ModalCreate";
import { ModalRedeemcode } from "./ModalRedeemcode";
import { Link } from "react-router-dom";

export const TableRedeemcodeList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification, modal } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const [openRedeemcodeModal, setOpenRedeemcodeModal] = useState(false);
	const [forceFetch, setForceFetch] = useState(0);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);

	const [fetchGetRiskifyRedeemcodeList] = useFetchGetRiskifyRedeemcodeList();
	const [fetchRiskifyRedeemcodeCancel] = useFetchRiskifyRedeemcodeCancel();

	const items = [
		{
			label: "作废",
			key: "cancel",
		},
	];

	const statusMap = {
		1: <Tag color="orange">WAITING</Tag>,
		2: <Tag color="green">SUCCESS</Tag>,
		3: <Tag color="gray">EXPIRED</Tag>,
		4: <Tag color="red">DELETED</Tag>,
	};

	const isSingleMap = {
		1: <Tag color="#feb019">单次</Tag>,
		2: <Tag color="#2db7f5">周期</Tag>,
	};

	const columns = [
		{
			title: "UID",
			dataIndex: "user",
			render:(_,record) =>(
				<Link target="_blank" to={`/riskify/user/detail?uid=${record.user}&type=insight`}>{record.user}</Link>
			)
		},
		{
			title: "类别",
			dataIndex: "category",
		},
		{
			title: "Code",
			dataIndex: "code",
		},
		{
			title: "周期/单次",
			dataIndex: "is_single",
			render: (_, record) => (
				<>{isSingleMap[record.is_single]}</>
			)
		},
		{
			title: "兑换内容",
			dataIndex: "target",
		},
		{
			title: "状态",
			dataIndex: "status",
			render: (_, record) => (
				<>{statusMap[record.status]}</>
			)
		},
		{
			title: "使用时间",
			dataIndex: "time_used",
			render: (_, record) => (
				<span>{record.time_used?getDateCN(record.time_used):"-"}</span>
			)
		},
		{
			title: "过期时间",
			dataIndex: "time_expired",
			render: (_, record) => (
				<span>{record.time_expired?getDateCN(record.time_expired):"-"}</span>
			)
		},
		{
			title: "操作",
			dataIndex: "operate",
			width: 80,
			render: (_, record) => (
				record.status === 1 &&
				<Dropdown
					menu={{
						items,
						onClick: (item) => {
							if (item.key === "cancel") {
								modal.confirm({
									title: "确认提示",
									content: "是否作废此redeemcode？",
									onOk() {
										return new Promise((resolve, reject) => {
											fetchRiskifyRedeemcodeCancel({
												app: "insight",
												status: 1,
												code:record.code
											}, {
												success: (data) => {
													setForceFetch(forceFetch => forceFetch + 1);
													notification.success({ 'message': "已作废！" });
												},
												error: (error) => {
													notification.error({ 'message': langError(error.code) });
												},
											});
											setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
										}).catch(() => console.log('Oops errors!'));
									},
								});
							}
						},
					}}
				>
					<a onClick={(e) => e.stopPropagation()}>
						<Space>
							<EllipsisOutlined style={{ fontSize: 24 }} />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData, app: "insight", pagesize: pageSize };
			fetchGetRiskifyRedeemcodeList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					// setPageSize(data.page.limit);
					setListData(data.list);
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData, forceFetch, pageSize]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	const handleChangePageSize = (current, size) => {
        setPageSize(size);
		window.scroll(0, 0);
    }

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<>
								<Space>
									<Button type="primary" onClick={() => { setOpenRedeemcodeModal(true) }}>批量导出Redeemcode</Button>
									<Button type="primary" onClick={() => { setOpenCreateModal(true) }}>创建</Button>
								</Space>
							</>
							
						</Row>
					</Space>

					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: true,
							onChange: handleChangePage,
							pageSizeOptions: [20, 50, 100, 500, 1000, 2000],
							onShowSizeChange: handleChangePageSize,
							showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} 页`
						}}
						rowKey={record => record.code}
					/>
					<ModalCreate
						open={openCreateModal}
						onCancel={() => setOpenCreateModal(false)}
						onHandleUpdateInfo={() => setForceFetch(forceFetch => forceFetch + 1)}
					/>
					<ModalRedeemcode
						open={openRedeemcodeModal}
						onCancel={() => {setOpenRedeemcodeModal(false)}}
						pageCurrent={pageCurrent}
						filterData={filterData}
						pageSize={pageSize}
					/>
				</>
			)}
		</Fragment>
	);
};
