import React from "react";
import zhCN from 'antd/locale/zh_CN';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { App, ConfigProvider } from "antd";
import config from "./config";
import PageUser from "./pages/admin/user";
import PageApp from "./pages/admin/app";
import PageInsightUser from "./pages/insight/user";

import PageLarkUser from "./pages/lark/user";
import PageLarkInvoice from "./pages/lark/invoice";
import PageLarkEnrich from "./pages/lark/enrich";

import PageDataEstimate from "./pages/data/estimate";
import PageDataEstimateDetails from "./pages/data/estimate/detail";

import PageMailboxInbox from "./pages/mailbox/inbox";
import PageEDMCover from "./pages/edm/cover";
import PageEDMCoverEdit from "./pages/edm/cover/cover-edit";
import PageEDMNewsletterEdit from "./pages/edm/cover/newsletter-edit";
import PageEDMTask from "./pages/edm/task";

import PageMsUser from "./pages/ms/user";
import PageMsInvoice from "./pages/ms/invoice";
import PageMsInvoiceDetail from "./pages/ms/invoice/detail";
import PageMsVisitor from "./pages/ms/visitor";
import PageMsVisitorDetails from "./pages/ms/visitor/detail";
import PageMsVerifier from "./pages/ms/verifier";
import PageMsCleanse from "./pages/ms/cleanse";
import PageMsLog from "./pages/ms/log";
import PageMsPromocode from "./pages/ms/promocode";
import PageMsSequence from "./pages/ms/sequence";
import PageMsEnrichment from "./pages/ms/enrichment";
import PageMsSubscription from "./pages/ms/subscription";
import PageMsFeedback from "./pages/ms/feedback";
import PageMsSystemLog from "./pages/ms/system-log";
import PageMsDashboard from "./pages/ms/dashboard";
import PageMsUserDetails from "./pages/ms/user/detail";
import PageMsMail from "./pages/ms/mail";
import PageMsApiKey from "./pages/ms/api-key";
import PageMsApiLog from "./pages/ms/api-log";
import PageMsRedeemCode from "./pages/ms/redeem-code";
import PageMsRecentSearch from "./pages/ms/recent-search";
import PageMsTimeline from "./pages/ms/timeline";
import PageMsChat from "./pages/ms/chat";


import PageOperationDomain from "./pages/operation/domain";
import PageOperationDaemon from "./pages/operation/daemon";
import PageError404 from "./pages/error/404";
import PageError403 from "./pages/error/403";
import PageLogin from "./pages/login";
import RouteAuthority from "./RouteAuthority";
import PageMsPartner from "pages/ms/partner";
import PageOperationFroxy from "pages/operation/froxy";
import PageOperationWorkflow from "pages/operation/workflow";
import PageOperationMonitor from "pages/operation/monitor";
import PageOperationStatus from "pages/operation/status";
import PageBlankTemplate from "pages/template/inbox";
import PageBemUser from "pages/bem/user";
import PageBemUserDetails from "pages/bem/user/detail";
import PageBemBillings from "pages/bem/billings";
import PageBemFeedback from "pages/bem/feedback";
import PageBemLogs from "pages/bem/logs";
import PageEdmTaskDetails from "pages/edm/task/details";
import PageBemPackage from "pages/bem/package";
import PageBemPackageDetails from "pages/bem/package/detail";
import PageBemRedeemcode from "pages/bem/redeemcode";
import PageBemPackagecombines from "pages/bem/packagecombines";
import PageBDLabDataIndex from "pages/bd_lab/data";
import PageDataProject from "pages/data/project";
import PageDataConfig from "pages/data/config";
import PageDataConfigDetails from "pages/data/config/detail";
import PageRisikyUser from "pages/riskify/user";
import PageRiskifyUserDetails from "pages/riskify/user/detail";
import PageRiskifyBillings from "pages/riskify/billings";
import PageRiskifyPackage from "pages/riskify/package";
import PageRiskifyPackageDetails from "pages/riskify/package/detail";
import PageRiskifyPackagecombines from "pages/riskify/packagecombines";
import PageRiskifyRedeemcode from "pages/riskify/redeemcode";
import PageRiskifyFeedback from "pages/riskify/feedback";
import PageRiskifyLogs from "pages/riskify/logs";
import PageMsPageMsComplaint from "pages/ms/complaint";

const Main = () => {
	const token = localStorage.getItem(config.keyStorageToken);
	const mainApp = localStorage.getItem(config.keyStorageApp);

	const themeOptions = {
		token: {
			// colorPrimary: '#219084',
		},
	};

	let appPath = '';

	if (mainApp) {
		appPath = `/${mainApp}`;
	} else {
		appPath = config.pathApp;
	}

	return (
		<div>
			<ConfigProvider theme={themeOptions} locale={zhCN}>
				<App>
					<BrowserRouter>
						<Routes>
							<Route
								path="/"
								element={
									token ? (
										<Navigate to={appPath} replace />
									) : (
										<Navigate to={config.pathLogin} replace />
									)
								}
							></Route>
							<Route
								path="/login"
								element={
									token ? (
										<Navigate to={appPath} replace />
									) : (
										<PageLogin />
									)
								}
							></Route>

							<Route path="/app" element={<PageApp />}></Route>
							<Route path="/user" element={<PageUser />}></Route>
							<Route path="/insight/user" element={<RouteAuthority authKey="insight/user" element={<PageInsightUser />}></RouteAuthority>}></Route>
							<Route path="/insight" element={<RouteAuthority authKey="insight/user" element={<Navigate to="/insight/user" replace />}></RouteAuthority>}></Route>


							<Route path="/lark/user" element={<RouteAuthority authKey="lark/user" element={<PageLarkUser />}></RouteAuthority>}></Route>
							<Route path="/lark/invoice" element={<RouteAuthority authKey="lark/invoice" element={<PageLarkInvoice />}></RouteAuthority>}></Route>
							<Route path="/lark/enrich" element={<RouteAuthority authKey="lark/enrich" element={<PageLarkEnrich />}></RouteAuthority>}></Route>
							<Route path="/lark" element={<RouteAuthority authKey="lark/user" element={<Navigate to="/lark/user" replace />}></RouteAuthority>}></Route>

							<Route path="/data/project" element={<RouteAuthority authKey="data/project" element={<PageDataProject />}></RouteAuthority>}></Route>
							<Route path="/data/config/detail" element={<RouteAuthority authKey="data/config/detail" element={<PageDataConfigDetails />}></RouteAuthority>}></Route>
							<Route path="/data/config" element={<RouteAuthority authKey="data/config" element={<PageDataConfig />}></RouteAuthority>}></Route>
							<Route path="/data/estimate/detail" element={<RouteAuthority authKey="data/estimate/detail" element={<PageDataEstimateDetails />}></RouteAuthority>}></Route>
							<Route path="/data/estimate" element={<RouteAuthority authKey="data/estimate" element={<PageDataEstimate />}></RouteAuthority>}></Route>
							<Route path="/data" element={<RouteAuthority authKey="data/project" element={<Navigate to="/data/project" replace />}></RouteAuthority>}></Route>

							<Route path="/bd_lab/data" element={<RouteAuthority authKey="bd_lab/data" element={<PageBDLabDataIndex />}></RouteAuthority>}></Route>
							<Route path="/bd_lab" element={<RouteAuthority authKey="bd_lab/data" element={<Navigate to="/bd_lab/data" replace />}></RouteAuthority>}></Route>

							<Route path="/mailbox/inbox" element={<RouteAuthority authKey="mailbox/inbox" element={<PageMailboxInbox />}></RouteAuthority>}></Route>
							<Route path="/mailbox" element={<RouteAuthority authKey="mailbox/inbox" element={<Navigate to="/mailbox/inbox" replace />}></RouteAuthority>}></Route>

							<Route path="/edm/newsletter/edit" element={<PageEDMNewsletterEdit />}></Route>
							<Route path="/edm/cover/edit" element={<PageEDMCoverEdit />}></Route>
							<Route path="/edm/cover" element={<RouteAuthority authKey="edm/cover" element={<PageEDMCover />}></RouteAuthority>}></Route>
							<Route path="/edm/task/detail" element={<RouteAuthority authKey="edm/task/detail" element={<PageEdmTaskDetails />}></RouteAuthority>}></Route>
							<Route path="/edm/task" element={<RouteAuthority authKey="edm/task" element={<PageEDMTask />}></RouteAuthority>}></Route>
							<Route path="/edm" element={<RouteAuthority authKey="edm/cover" element={<Navigate to="/edm/cover" replace />}></RouteAuthority>}></Route>


							<Route path="/ad-system" element={<RouteAuthority authKey="ms/dashboard" element={<Navigate to="/ms/dashboard" replace />}></RouteAuthority>}></Route>
							<Route path="/ms/user" element={<RouteAuthority authKey="ms/user" element={<PageMsUser />}></RouteAuthority>}></Route>
							<Route path="/ms/invoice/detail" element={<RouteAuthority authKey="ms/invoice/detail" element={<PageMsInvoiceDetail />}></RouteAuthority>}></Route>
							<Route path="/ms/invoice"  element={<RouteAuthority authKey="ms/invoice" element={<PageMsInvoice />}></RouteAuthority>}></Route>
							<Route path="/ms/visitor/detail" element={<RouteAuthority authKey="ms/visitor/detail" element={<PageMsVisitorDetails />}></RouteAuthority>}></Route>
							<Route path="/ms/visitor" element={<RouteAuthority authKey="ms/visitor" element={<PageMsVisitor />}></RouteAuthority>}></Route>
							<Route path="/ms/verify" element={<RouteAuthority authKey="ms/verify" element={<PageMsVerifier />}></RouteAuthority>}></Route>
							<Route path="/ms/cleanse" element={<RouteAuthority authKey="ms/cleanse" element={<PageMsCleanse />}></RouteAuthority>}></Route>
							<Route path="/ms/log" element={<RouteAuthority authKey="ms/log" element={<PageMsLog />}></RouteAuthority>}></Route>
							<Route path="/ms/complaint" element={<RouteAuthority authKey="ms/complaint" element={<PageMsPageMsComplaint />}></RouteAuthority>}></Route>
							<Route path="/ms/promocode" element={<RouteAuthority authKey="ms/promocode" element={<PageMsPromocode />}></RouteAuthority>}></Route>
							<Route path="/ms/partner" element={<RouteAuthority authKey="ms/partner" element={<PageMsPartner />}></RouteAuthority>}></Route>
							<Route path="/ms/sequence" element={<RouteAuthority authKey="ms/sequence" element={<PageMsSequence />}></RouteAuthority>}></Route>
							<Route path="/ms/enrichment" element={<RouteAuthority authKey="ms/enrichment" element={<PageMsEnrichment />}></RouteAuthority>}></Route>
							<Route path="/ms/dashboard" element={<RouteAuthority authKey="ms/dashboard" element={<PageMsDashboard />}></RouteAuthority>}></Route>
							<Route path="/ms" element={<RouteAuthority authKey="ms/dashboard" element={<Navigate to="/ms/dashboard" replace />}></RouteAuthority>}></Route>
							<Route path="/ms/user/detail" element={<RouteAuthority authKey="ms/user/detail" element={<PageMsUserDetails />}></RouteAuthority>}></Route>
							<Route path="/ms/subscription" element={<RouteAuthority authKey="ms/subscription" element={<PageMsSubscription />}></RouteAuthority>}></Route>
							<Route path="/ms/feedback" element={<RouteAuthority authKey="ms/feedback" element={<PageMsFeedback />}></RouteAuthority>}></Route>
							<Route path="/ms/systemlog" element={<RouteAuthority authKey="ms/systemlog" element={<PageMsSystemLog />}></RouteAuthority>}></Route>
							<Route path="/ms/mail" element={<RouteAuthority authKey="ms/mail" element={<PageMsMail />}></RouteAuthority>}></Route>
							<Route path="/ms/redeemcode" element={<RouteAuthority authKey="ms/redeemcode" element={<PageMsRedeemCode />}></RouteAuthority>}></Route>
							<Route path="/ms/apikey" element={<RouteAuthority authKey="ms/apikey" element={<PageMsApiKey />}></RouteAuthority>}></Route>
							<Route path="/ms/apilog" element={<RouteAuthority authKey="ms/apilog" element={<PageMsApiLog />}></RouteAuthority>}></Route>
							<Route path="/ms/recentsearch" element={<RouteAuthority authKey="ms/recentsearch" element={<PageMsRecentSearch />} ></RouteAuthority>}></Route>
							<Route path="/ms/timeline" element={<RouteAuthority authKey="ms/timeline" element={<PageMsTimeline />} ></RouteAuthority>}></Route>
							<Route path="/ms/chat" element={<RouteAuthority authKey="ms/chat" element={<PageMsChat />} ></RouteAuthority>}></Route>

							<Route path="/operation/domain" element={<RouteAuthority authKey="operation/domain" element={<PageOperationDomain />} ></RouteAuthority>}></Route>
							<Route path="/operation/daemon" element={<RouteAuthority authKey="operation/daemon" element={<PageOperationDaemon />} ></RouteAuthority>}></Route>
							<Route path="/operation/workflow" element={<RouteAuthority authKey="operation/workflow" element={<PageOperationWorkflow />} ></RouteAuthority>}></Route>
							<Route path="/operation/monitor" element={<RouteAuthority authKey="operation/monitor" element={<PageOperationMonitor />} ></RouteAuthority>}></Route>
							<Route path="/operation/status" element={<RouteAuthority authKey="operation/status" element={<PageOperationStatus />} ></RouteAuthority>}></Route>
							<Route path="/operation/froxy" element={<RouteAuthority authKey="operation/froxy" element={<PageOperationFroxy />} ></RouteAuthority>}></Route>

							<Route path="/bem/user/detail" element={<RouteAuthority authKey="bem/user/detail" element={<PageBemUserDetails />} ></RouteAuthority>}></Route>
							<Route path="/bem/user" element={<RouteAuthority authKey="bem/user" element={<PageBemUser />} ></RouteAuthority>}></Route>
							<Route path="/bem/billings" element={<RouteAuthority authKey="bem/billings" element={<PageBemBillings />} ></RouteAuthority>}></Route>
							<Route path="/bem/feedback" element={<RouteAuthority authKey="bem/feedback" element={<PageBemFeedback />} ></RouteAuthority>}></Route>
							<Route path="/bem/log" element={<RouteAuthority authKey="bem/log" element={<PageBemLogs />} ></RouteAuthority>}></Route>
							<Route path="/bem/package/detail" element={<RouteAuthority authKey="bem/package/detail" element={<PageBemPackageDetails />} ></RouteAuthority>}></Route>
							<Route path="/bem/package" element={<RouteAuthority authKey="bem/package" element={<PageBemPackage />} ></RouteAuthority>}></Route>
							<Route path="/bem/redeemcode" element={<RouteAuthority authKey="bem/redeemcode" element={<PageBemRedeemcode />} ></RouteAuthority>}></Route>
							<Route path="/bem/packagecombines" element={<RouteAuthority authKey="bem/packagecombines" element={<PageBemPackagecombines/>} ></RouteAuthority>}></Route>

							<Route path="/riskify/user/detail" element={<RouteAuthority authKey="riskify/user/detail" element={<PageRiskifyUserDetails />} ></RouteAuthority>}></Route>
							<Route path="/riskify/user" element={<RouteAuthority authKey="riskify/user" element={<PageRisikyUser />} ></RouteAuthority>}></Route>
							<Route path="/riskify/billings" element={<RouteAuthority authKey="riskify/billings" element={<PageRiskifyBillings />} ></RouteAuthority>}></Route>
							<Route path="/riskify/feedback" element={<RouteAuthority authKey="riskify/feedback" element={<PageRiskifyFeedback />} ></RouteAuthority>}></Route>
							<Route path="/riskify/log" element={<RouteAuthority authKey="riskify/log" element={<PageRiskifyLogs />} ></RouteAuthority>}></Route>
							<Route path="/riskify/package/detail" element={<RouteAuthority authKey="riskify/package/detail" element={<PageRiskifyPackageDetails />} ></RouteAuthority>}></Route>
							<Route path="/riskify/package" element={<RouteAuthority authKey="riskify/package" element={<PageRiskifyPackage />} ></RouteAuthority>}></Route>
							<Route path="/riskify/redeemcode" element={<RouteAuthority authKey="riskify/redeemcode" element={<PageRiskifyRedeemcode />} ></RouteAuthority>}></Route>
							<Route path="/riskify/packagecombines" element={<RouteAuthority authKey="riskify/packagecombines" element={<PageRiskifyPackagecombines />} ></RouteAuthority>}></Route>

							<Route path="/blank-template" element={<PageBlankTemplate />}></Route>

							<Route path="/403" element={<PageError403 />}></Route>
							<Route path="*" element={<PageError404 />} />
						</Routes>
					</BrowserRouter>
				</App>
			</ConfigProvider>
		</div>
	);
};

export default Main;
