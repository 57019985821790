import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import {
	App,
	Row,
	Space,
	Table,
	Typography,
	Skeleton,
	Button,
	Tag,
} from "antd";
import { useFetchGetMsComplaintList } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalAdd } from "./ModalAdd";

export const TableList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [dataTotal, setDataTotal] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [forceList, setForceList] = useState(0);

	const [fetchGetMsComplaintList] = useFetchGetMsComplaintList();

	const mapStatus = {
		1: <Tag color="orange">处理中</Tag>,
		2: <Tag color="green">已完成</Tag>,
	};

	const columns = [
		{
			title: "Type",
			dataIndex: "type",
		},
		{
			title: "标识",
			dataIndex: "value",
		},
		{
			title: "Status",
			dataIndex: "status",
			render: (_, record) => (
				<span>{mapStatus[record?.status]}</span>
			),
		},
		{
			title: "创建时间",
			dataIndex: "time_add",
			render: (_, record) => (
				<span>{getDateCN(record?.time_add)}</span>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetMsComplaintList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData, forceList]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<Button type="primary" onClick={()=> setOpenModal(true)}>添加投诉信息</Button>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						rowKey={(item) => item.id}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
					/>
					<ModalAdd
						open={openModal}
						onCancel={() => { setOpenModal(false)}}
						onUpdateList={() => setForceList(forceList => forceList + 1)}
					/>
				</>
			)}
		</Fragment>
	);
};
